<template>
    <div class="card" style="min-height: calc(100vh - 180px)">
        <div class="bg-blue-light p-1">
            <TitleButton
                btnTitle="Add New"
                :showBtn="true"
                :showAddNew="true"
                title="IPD Invoice list"
                @onClickAddNewButton="navigateToAddPage"
                @onClickCloseButton="navigateToPrevPage"
            />
        </div>

        <div class="my-2 px-2">
            <DateQuerySetter
                btnTitle="Go"
                @onClickGo="onClickGo"
            >
            </DateQuerySetter>
        </div>
        <div class="col-12 px-2 mt-2">
            <IPDListTable :purchase="purchaseList"/>
        </div>
        <div class="px-2"><Pagination :offset="offset" @onPageChange="onPageChange" ref="pagination"/></div>
    </div>
</template>

<script setup>
import DateQuerySetter from '@/components/atom/DateQuerySetter'
import {computed, inject, onMounted, ref} from 'vue'
import {useStore} from 'vuex'
import {useRoute, useRouter} from 'vue-router'
import handlePurchase from '@/services/modules/purchase'
import TitleButton from '@/components/atom/TitleButton'
import Pagination from '@/components/atom/Pagination'
import handleContact from "@/services/modules/contact";
import IPDListTable from "@/components/molecule/company/hospital/billing/ipd-bill/ListTable.vue";
import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";

const router = useRouter()
const store = useStore()
let offset = ref(20)
let purchaseList = ref([])
const route = useRoute()
const showError =  inject('showError');
const showSuccess =  inject('showSuccess');

const {fetchSalesList} = handlePurchase()
const {fetchContactProfiles} = handleContact();
const {formatPatientLabel} = useAsyncDropdownHelper()

const additionalQuery = {
    type: "patient"
}

const companyId = computed(() => {
    return route.params.companyId
})
const startDate = computed(() => {
    return route.query.start
})
const endDate = computed(() => {
    return route.query.end
})
const page = computed(() => {
    return route.query.page
})

function navigateToAddPage() {
    router.push({name: 'ipd-bill-add', params: route.params, query:route.query})
}

function navigateToPrevPage() {
  const params = route.params;
  params.pageId = route.query.pageId
  delete route.query.pageId
  router.push({name: 'patient-register',
    params: route.params,
    query: route.query
  })
}

function getQuery () {
    let query = '?company_id=' + companyId.value
    query += '&sale_type=ipd_invoice'
    query += `&ipd_register_id=${route.params.ipdRegisterId}`
    query += '&is_belong_to_user=1'
    if(!startDate.value && !endDate.value) return query
    if(startDate.value) query += '&start_date='+startDate.value
    if(endDate.value) query += '&end_date='+endDate.value
    if(page.value) query += '&page='+page.value
    query += '&offset='+offset.value
    return query
}

function onClickGo() {
    fetchSalesList(getQuery())
        .then(res => {
            if(res.status) {
                purchaseList.value = res.date.data
                setPagination(res.date)
            }else {
                purchaseList.value = []
                store.commit("resetPagination")
            }

        })
}

function setPagination(data) {
    if(!data.data.length) return
    store.commit('setPaginationData', {
        records: data.total,
        perPage: data.per_page,
        currentPage: data.current_page,
    })
}

function onPageChange(page) {
    let routeQuery = Object.assign({}, route.query)
    routeQuery.page = page
    router.push({path: route.path, query: routeQuery})
    onClickGo()
}

onMounted(() => {
    onClickGo()
})

</script>
